import { McButton, McLoadingIndicator } from '@maersk-global/mds-react-wrapper';
import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Drawer from '../../../../common/components/Drawer/Drawer';
import FormattedMessage from '../../../../common/FormattedMessage';
import { Back } from '../../../../common/icons';
import { capacityService } from '../../../../common/service/capacity/capacity-service';
import { Changelog } from '../../../types';
import EmptyChangelog from '../steps/empty-changelog';
import Steps from '../steps/steps';

export type ChangelogDrawerProps = {
  id: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ChangelogDrawer: FunctionComponent<ChangelogDrawerProps> = ({ open, setOpen, id }) => {
  const { formatMessage } = useIntl();
  const [changelog, setChangelog] = useState<Changelog[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);
      capacityService()
        .getChangelog({ id })
        .then((result) => {
          if (result) {
            setChangelog(result);
          }
          setLoading(false);
        });
    }
  }, [open]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer title="" open={open} setOpen={setOpen} onRequestClose={onClose} noFooter noTitle>
      <div className="ChangelogDrawerContent">
        <div className="ChangelogDrawerContent__title">
          <span className="ChangelogDrawerContent__title__back" onClick={() => setOpen(false)}>
            <Back />
          </span>
          <h3>{formatMessage({ id: 'changelog' })}</h3>
        </div>

        {loading ? (
          <McLoadingIndicator label={formatMessage({ id: 'fetchingData' })} />
        ) : changelog.length > 0 ? (
          <Steps changelog={changelog} />
        ) : (
          <EmptyChangelog />
        )}

        <div className="ChangelogDrawerFooter">
          <McButton type="button" appearance="neutral" click={() => setOpen(false)}>
            <FormattedMessage id="back" />
          </McButton>
        </div>
      </div>
    </Drawer>
  );
};

export default ChangelogDrawer;
