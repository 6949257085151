import { McIcon } from '@maersk-global/mds-react-wrapper';
import React from 'react';
import { AdjustableComponent, useClasses } from '../../hooks/useClasses';
import UserDropdown, { UserDropdownAction } from '../UserDropdown/UserDropdown';
import { useIntl } from 'react-intl';

export type TopbarProps = {
  onLogout: () => void;
  username?: string;
  title?: string;
};

export type TopbarStyles = {
  root: string;
  title: string;
  userDropdown: string;
  addons: string;
  icon: string;
  iconWrapper: string;
  content: string;
  appName: string;
};

const Topbar: AdjustableComponent<TopbarProps, TopbarStyles> = ({ onLogout, username, title, classes }) => {
  const styles = useClasses(
    {
      root: 'Topbar',
      title: 'Topbar__title',
      userDropdown: 'Topbar__user-dropdown',
      icon: 'Topbar__icon',
      iconWrapper: 'Topbar__icon-wrapper',
      addons: 'Topbar__addons',
      content: 'Topbar__content',
      appName: 'Topbar__app-name',
    },
    classes,
  );
  const { formatMessage } = useIntl();

  const userDropdownActions: UserDropdownAction[] = [
    {
      title: formatMessage({ id: 'user_dropdown_logout' }),
      icon: 'door-arrow-right',
      handler: onLogout,
    },
  ];

  return (
    <div className={styles.root}>
      <div>
        <div className={styles.iconWrapper}>
          <McIcon className={styles.icon} icon="dot-grid" />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.addons}>
          <span className={styles.appName}>Port Data</span>
          <div className={styles.userDropdown}>
            <UserDropdown
              classes={(current) => ({
                ...current,
                root: `${current.root} Topbar__user-dropdown-button`,
              })}
              displayName={username || ''}
              userName={username || ''}
              actions={userDropdownActions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
