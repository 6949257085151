enum OCCType {
  HOURS_30_36 = 'HOURS_30_36',
  HOURS_36_42 = 'HOURS_36_42',
  HOURS_42_48 = 'HOURS_42_48',
  HOURS_48_PLUS = 'HOURS_48_PLUS',
}
export enum Period {
  Current = 'CURRENT',
  Next = 'NEXT',
}

type CapacityEntry = {
  from: string;
  to: string;
  vesselPairLimits: number;
  period: Period;
};

type OCCValue = {
  capacityEntries: CapacityEntry[];
  terminalCode: string;
  terminalCodeAndType: string;
  type: OCCType;
  nextPeriod: string;
};

export type UpdateOCCRequest = { data: CapacityEntry & { terminalCodeAndType: string } };

export type OCCResponse = Record<OCCType, OCCValue>;
