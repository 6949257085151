import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { McLoadingIndicator, McTable } from '@maersk-global/mds-react-wrapper';
import type { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';
import { useRecoilState } from 'recoil';
import { selectedRuleForEditAtom } from '../../../state';
import useOctw from '../../capacity/hooks/useOctw';
import { useIntl } from '../../common/hooks/useIntl';
import EditRuleDrawer from './EditRuleDrawer/EditRuleDrawer';

const OctwAdminView: FunctionComponent = () => {
  const { octwRules, loadingRules, fetchRules } = useOctw();
  const { formatMessage } = useIntl();
  const [selectedRuleForEdit, setSelectedRuleForEdit] = useRecoilState(selectedRuleForEditAtom);

  useEffect(() => {
    fetchRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- by design
  }, []);

  const columns: Array<TableColumn> = [
    {
      id: 'terminal',
      label: formatMessage({ id: 'octwAdminTableColumnTerminal' }),
    },
    {
      id: 'code',
      label: formatMessage({ id: 'octwAdminTableColumnCode' }),
      tabularFigures: true,
      align: 'right',
    },
    {
      id: 'ruleName',
      label: formatMessage({ id: 'octwAdminTableColumnRuleName' }),
    },
    {
      id: 'deOtw',
      align: 'right',
      label: formatMessage({ id: 'defaultOctwAdminTableColumnOtw' }),
    },
    {
      id: 'exOtw',
      align: 'right',
      label: formatMessage({ id: 'exceptionalOctwAdminTableColumnOtw' }),
    },
  ];

  const data = useMemo(
    () =>
      octwRules?.map((rule) => ({
        terminal: rule.hubName,
        code: rule.apmtHubId,
        hubId: rule.hubId,
        ruleName: rule.ruleName,
        deOtw: `${rule.deOperationalConnectionTimeWindow} min`,
        exOtw: `${rule.exOperationalConnectionTimeWindow} min`,
        rule,
      })),
    [octwRules],
  );

  const onRowClick = (e) => {
    const row = e.detail.rowData;
    // Currently API is built using hubId as primary key. It's supposed to be unique but when it becomes duplicated, let's rethink this allocation
    setSelectedRuleForEdit(row.rule);
  };

  return (
    <div className="RulesContainer__admin-view">
      {loadingRules ? (
        <McLoadingIndicator label={formatMessage({ id: 'fetchingData' })} />
      ) : (
        <McTable rowclick={onRowClick} columns={columns} data={data} />
      )}
      <EditRuleDrawer open={Boolean(selectedRuleForEdit)} setOpen={() => setSelectedRuleForEdit(undefined)} />
    </div>
  );
};

export default OctwAdminView;
