import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import Drawer from '../../../common/components/Drawer/Drawer';
import { formattedNumberToInt } from '../../../common/helpers/utils';
import { AdjustableComponent, useClasses } from '../../../common/hooks/useClasses';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import FormikInputField from '../../../common/components/FormikInputField/FormikInputField';
import { McButton, McDateRange, McInputDate, McModal, McNotification } from '@maersk-global/mds-react-wrapper';
import z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import FormattedMessage from '../../../common/FormattedMessage';
import useCapacityData from '../../hooks/useCapacityData';
import ChangelogDrawer from '../changelog/changelog-drawer/ChangelogDrawer';
import { createDefaultValueDto } from '../dto/DefaultValueDto';
import { modifyDate } from '../../../common/helpers/modify-date';
import IMOInputField from '../IMODescription/IMOInputField';

export type EditDefaultValuesDrawerStyles = {
  field: string;
  buttons: string;
  content: string;
};

export type EditDefaultValuesDrawerProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess?: Function;
  selectedTerminal: string;
  id: string;
  defaultValues: Map<string, number>;
  type: 'yard' | 'soc';
};

const FormValidationSchemaSOC = z.object({
  terminalCode: z.string(),
  moves: z.number({ message: 'Value should be a number' }).positive(),
  moveCountStretchPercentage: z.number({ message: 'Value should be a number (0 - 100)' }).min(0).max(100),
  moveCountCriticalPercentage: z.number({ message: 'Value should be a number (0 - 100)' }).min(0).max(100),
});

const FormValidationSchemaYard = z.object({
  terminalCode: z.string(),
  oogUnits: z.number({ message: 'Value should be a number' }).positive(),
  imoUnits: z.number({ message: 'Value should be a number' }).positive(),
  yardCapacity: z.number({ message: 'Value should be a number' }).positive(),
  reeferPlugs: z.number({ message: 'Value should be a number' }).positive(),
});

const EditDefaultValuesDrawer: AdjustableComponent<EditDefaultValuesDrawerProps, EditDefaultValuesDrawerStyles> = ({
  classes,
  open,
  setOpen,
  onSuccess,
  selectedTerminal,
  id,
  defaultValues,
  type,
}) => {
  const styles = useClasses(
    {
      field: 'EditDefaultValuesDrawer__field',
      content: 'EditDefaultValuesDrawer__content',
      buttons: 'EditDefaultValuesDrawer__buttons',
    },
    classes,
  );
  const formikRef = useRef(null);
  const deleteModalRef = useRef(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [changelogOpen, setChangelogOpen] = useState(false);
  const { updateTemplate, deleteTemplate } = useCapacityData();

  const onClose = () => {
    setOpen(false);
  };

  const getFormInitialValues = () => {
    const baseValues = {
      id: id,
      terminalCode: selectedTerminal,
      startDate: defaultValues['startDate'],
      previousStartDate: defaultValues['previousStartDate'],
      nextStartDate: defaultValues['nextStartDate'],
      capacityDataType: 'TOTAL',
    };
    const socValues = {
      moves: formattedNumberToInt(defaultValues['moves']),
      moveCountStretchPercentage: defaultValues['moveCountStretchPercentage'],
      moveCountCriticalPercentage: defaultValues['moveCountCriticalPercentage'],
    };
    const yardValues = {
      yardCapacity: formattedNumberToInt(defaultValues['yardCapacity']),
      reeferPlugs: formattedNumberToInt(defaultValues['reeferPlugs']),
      imoUnits: formattedNumberToInt(defaultValues['imoUnits']),
      imoUnitsDescription: defaultValues['imoUnitsDescription'],
      oogUnits: formattedNumberToInt(defaultValues['oogUnits']),
    };
    return { ...baseValues, ...(type === 'soc' ? socValues : yardValues) };
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const dto = createDefaultValueDto(values, type);
    try {
      await updateTemplate(dto);
      onSuccess?.();
      onClose();
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Drawer
      title={'Edit default values'}
      open={open}
      setOpen={setOpen}
      onRequestClose={onClose}
      noFooter
      titleAddon={
        <div className="EditWeeklyValuesDrawer__changelog">
          <McButton
            type="button"
            appearance="secondary"
            variant="outlined"
            click={() => setChangelogOpen(true)}
            icon="arrow-anti-clockwise-clock"
          >
            <FormattedMessage id="changelog" />
          </McButton>
        </div>
      }
    >
      <Formik
        initialValues={getFormInitialValues()}
        enableReinitialize
        // @ts-ignore
        validationSchema={toFormikValidationSchema(type === 'soc' ? FormValidationSchemaSOC : FormValidationSchemaYard)}
        onSubmit={onSubmit}
        innerRef={formikRef} // Pass the ref to Formik
      >
        {({ isSubmitting, values }) => (
          <Form className="EditDefaultValuesDrawer__form">
            <div className={styles.content}>
              {type === 'soc' ? (
                <div style={{ backgroundColor: '#f0f0f0', padding: '0.5rem', borderRadius: '0.25rem' }}>
                  <div style={{ display: 'flex', gap: '2rem', alignItems: 'flex-start' }}>
                    {/* Move Count */}
                    <div style={{ flexBasis: '30%', textAlign: 'left' }}>
                      <label>Move count</label>
                      <Field label={''} type="number" name="moves" component={FormikInputField} />
                      <ErrorMessage name="moves" component="div" />
                    </div>

                    {/* Threshold #1 with Icon and Total */}
                    <div style={{ flexBasis: '30%', textAlign: 'left' }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <span style={{ backgroundColor: '#FFD029', width: '1rem', height: '1rem' }}></span>
                        <label>Threshold L2</label>
                      </div>
                      <Field
                        label={''}
                        type="number"
                        name="moveCountStretchPercentage"
                        suffix="%"
                        component={FormikInputField}
                      />
                      <ErrorMessage name="moveCountStretchPercentage" component="div" />
                      <div style={{ fontSize: '0.9rem', marginTop: '0.5rem', color: '#8A8A90' }}>
                        {/*//@ts-ignore*/}
                        Total: {printTotal(values.moves, values.moveCountStretchPercentage)}
                      </div>
                    </div>

                    {/* Threshold #2 with Icon and Total */}
                    <div style={{ flexBasis: '30%', textAlign: 'left' }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <span style={{ backgroundColor: '#FF6000', width: '1rem', height: '1rem' }}></span>
                        <label>Threshold L3</label>
                      </div>
                      <Field
                        label={''}
                        type="number"
                        name="moveCountCriticalPercentage"
                        suffix="%"
                        component={FormikInputField}
                      />
                      <ErrorMessage name="moveCountCriticalPercentage" component="div" />
                      <div style={{ fontSize: '0.9rem', marginTop: '0.5rem', color: '#8A8A90' }}>
                        {/*// @ts-ignore*/}
                        Total: {printTotal(values.moves, values.moveCountCriticalPercentage)}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <Field label={'Yard TEU capacity'} type="number" name="yardCapacity" component={FormikInputField} />
                    <ErrorMessage name="yardCapacity" component="div" />
                    <Field label={'Reefer plugs'} type="number" name="reeferPlugs" component={FormikInputField} />
                    <ErrorMessage name="reeferPlugs" component="div" />
                  </div>
                  <br />
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <IMOInputField isDrawerOpen={open} />
                    <Field label={'OOG units'} type="number" name="oogUnits" component={FormikInputField} />
                    <ErrorMessage name="oogUnits" component="div" />
                  </div>
                </>
              )}

              <br />
              <div>
                <h4>Validity period</h4>
                {/*// @ts-ignore*/}
                {formikRef.current?.values?.previousStartDate && (
                  <>
                    <McNotification
                      appearance="info"
                      body={
                        'Previous period: From ' +
                        // @ts-ignore
                        formikRef.current?.values?.previousStartDate +
                        ' to ' +
                        // @ts-ignore
                        modifyDate(formikRef.current?.values?.startDate?.split('T')[0], 'remove')
                      }
                      icon="info-circle"
                      verticalalign="middle"
                      actionsposition="right"
                    />
                    <br />
                  </>
                )}
                {/* Validity start date */}
                <McDateRange>
                  yearcap={20}
                  {/*set 'value'*/}
                  <McInputDate
                    slot={'to'}
                    label={'To'}
                    disabled
                    value={
                      // @ts-ignore
                      formikRef.current?.values?.nextStartDate
                        ? // @ts-ignore
                          modifyDate(formikRef.current?.values?.nextStartDate, 'remove')
                        : '2100-01-01'
                    }
                  ></McInputDate>
                  <McInputDate
                    slot={'from'}
                    label={'From'}
                    showweeknumbers={true}
                    customize={[
                      {
                        date: (date) => date.getDay() === 0 || (date.getDay() <= 6 && date.getDay() >= 2),
                        disabled: true,
                      },
                    ]}
                    min={
                      // @ts-ignore
                      formikRef.current?.values?.previousStartDate &&
                      // @ts-ignore
                      modifyDate(formikRef.current?.values?.previousStartDate, 'add')
                    }
                    max={
                      // @ts-ignore
                      formikRef.current?.values?.nextStartDate &&
                      // @ts-ignore
                      modifyDate(formikRef.current?.values?.nextStartDate, 'remove')
                    }
                    // @ts-ignore
                    disabled={formikRef.current?.values?.previousStartDate === undefined}
                    // @ts-ignore
                    value={formikRef.current?.values?.startDate?.split('T')[0]}
                    input={(e) => {
                      // @ts-ignore
                      formikRef.current?.setFieldValue('startDate', e.target.value + 'T00:00:00Z');
                    }}
                  ></McInputDate>
                </McDateRange>
              </div>

              <Field type="hidden" name="hubId" />
            </div>

            <div className={styles.buttons}>
              <McButton type="button" appearance="neutral" click={() => onClose()}>
                <FormattedMessage id="cancel" />
              </McButton>
              <McButton disabled={isSubmitting} type="submit" appearance="primary">
                <FormattedMessage id={isSubmitting ? 'updateRuleDrawerFormSubmitting' : 'updateRuleDrawerFormSubmit'} />
              </McButton>
              {/*// @ts-ignore*/}
              {formikRef.current?.values?.previousStartDate && (
                <McButton
                  type="button"
                  appearance="error"
                  click={() => {
                    if (deleteModalRef.current) {
                      // @ts-ignore
                      deleteModalRef.current.show();
                    }
                  }}
                >
                  <FormattedMessage id="delete" />
                </McButton>
              )}
              <McModal ref={deleteModalRef} dimension={'small'} heading={'Delete Default Value'}>
                <p id={'question'}>Are you sure you want to delete this default value?</p>
                <McButton
                  slot="secondaryAction"
                  appearance="neutral"
                  variant="outlined"
                  dialogaction="cancel"
                  label="Cancel"
                />
                <McButton
                  id="confirm"
                  slot="primaryAction"
                  appearance="primary"
                  loading={deleteLoading}
                  click={async () => {
                    setDeleteLoading(true);
                    await deleteTemplate(values?.id);
                    onClose();
                  }}
                  label="Delete"
                />
              </McModal>
            </div>
          </Form>
        )}
      </Formik>

      <ChangelogDrawer open={changelogOpen} setOpen={setChangelogOpen} id={id} />
    </Drawer>
  );
};

const printTotal = (moves: number, percentage: number) => {
  return moves && percentage ? moves + Math.ceil((moves * percentage) / 100) : '-';
};

export default EditDefaultValuesDrawer;
