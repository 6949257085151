import { enqueueSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { octwRulesAtom } from '../../../state';
import { octwService } from '../../common/service/octw/octw-service';
import { OCTWEntry, OCTWResponse } from '../../common/service/octw/types';
import { useState } from 'react';

const useOctw = () => {
  const [octwForTerminal, setOctwForTerminal] = useState<OCTWResponse>();
  const [octwRules, setOctwRules] = useRecoilState(octwRulesAtom);
  const [loading, setLoading] = useState(false);
  const [loadingRules, setLoadingRules] = useState(false);
  const { formatMessage } = useIntl();

  const fetchRules = () => {
    setLoadingRules(true);
    return octwService()
      .fetchOCTWRules()
      .then((result) => {
        if (result) {
          setOctwRules(result);
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
        setLoadingRules(false);
      });
  };

  const fetchOCTW = (terminal: string) => {
    setLoading(true);
    return octwService()
      .fetchOCTWForTerminal({ terminal })
      .then((result) => {
        if (result) {
          setOctwForTerminal(result);
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
        setLoading(false);
      });
  };

  const updateOCTW = (data: OCTWEntry) => {
    return octwService()
      .updateOCTWRule({ data })
      .then((result) => {
        if (result) {
          enqueueSnackbar('Entry saved successfully!', { variant: 'success' });
          fetchRules();
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
      });
  };

  return {
    loading,
    loadingRules,
    octwForTerminal,
    octwRules,
    fetchOCTW,
    updateOCTW,
    fetchRules,
  };
};

export default useOctw;
