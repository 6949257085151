import { McButton, McTable } from '@maersk-global/mds-react-wrapper';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { dateStringToISO } from '../../../common/helpers/utils';
import { mapCapacityType } from '../utils/capacityTypeMapper';
import { columns } from './consts';

type OCCTabProps = {
  occ: any;
  occDateType: string;
  setSelectedOccRow: Function;
};

const NextPeriodTable: React.FC<OCCTabProps> = (props) => {
  const { occ, occDateType, setSelectedOccRow } = props;
  const { formatMessage } = useIntl();

  const data = useMemo(
    () =>
      occ &&
      occ[occDateType] &&
      occ[occDateType].capacityEntries
        .filter((item) => item.period === 'NEXT')
        .map((item, index) => ({
          id: index,
          from: mapCapacityType(item.from),
          to: mapCapacityType(item.to),
          vesselPairLimits: `${item.vesselPairLimits}`,
          dateType: occDateType,
          period: `${item.period} (${dateStringToISO(occ[occDateType].nextPeriod)})`,
        })),
    [occ, occDateType],
  );

  return (
    <>
      <div className="OCCNextPeriod">
        <McTable columns={columns} data={data} rowclick={(e) => setSelectedOccRow(e.detail.rowData)}>
          {data?.map((row) => (
            <div key={`${row.id}_action`} slot={`${row.id}_action`}>
              <McButton fit="small" variant="plain" appearance="primary" icon="pencil">
                {formatMessage({ id: 'edit' })}
              </McButton>
            </div>
          ))}
        </McTable>
      </div>
    </>
  );
};

export default NextPeriodTable;
