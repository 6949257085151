import { McButton, McInput } from '@maersk-global/mds-react-wrapper';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import useOctw from '../../hooks/useOctw';

type OctwFormProps = {
  selectedTerminal: string;
};

type Values = {
  deOperationalConnectionTimeWindow?: string;
  exOperationalConnectionTimeWindow?: string;
};

const OctwForm: React.FC<OctwFormProps> = ({ selectedTerminal }) => {
  const { formatMessage } = useIntl();
  const { fetchOCTW, updateOCTW, octwForTerminal } = useOctw();
  const [editMode, setEditMode] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [newValues, setNewValues] = useState<Values>();
  const refE = useRef(null);
  const refD = useRef(null);

  useEffect(() => {
    fetchOCTW(selectedTerminal);
  }, [selectedTerminal]);

  useEffect(() => {
    if (octwForTerminal) {
      setNewValues({
        deOperationalConnectionTimeWindow: octwForTerminal.deOperationalConnectionTimeWindow,
        exOperationalConnectionTimeWindow: octwForTerminal.exOperationalConnectionTimeWindow,
      });
    }
  }, [octwForTerminal]);

  useEffect(() => {
    if (submitting && octwForTerminal) {
      const data = { ...octwForTerminal, ...newValues };
      updateOCTW(data);
      setEditMode(false);
      setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- by design
  }, [submitting]);

  // It needs to use this hack because there's no other way to access input elem for McInput
  useEffect(() => {
    // @ts-ignore
    const inputElemE = refE.current?.shadowRoot?.querySelector('input');
    // @ts-ignore
    const inputElemD = refD.current?.shadowRoot?.querySelector('input');
    const style = 'text-align: right';
    if (inputElemE) {
      // @ts-ignore
      inputElemE.style = style;
    }
    if (inputElemD) {
      // @ts-ignore
      inputElemD.style = style;
    }
  }, [refE.current, refD.current]);

  const handleInput = (
    e: InputEvent,
    type: 'deOperationalConnectionTimeWindow' | 'exOperationalConnectionTimeWindow',
  ) => {
    const value = (e.target as HTMLInputElement).value;
    setNewValues((prev) => ({ ...prev, [type]: value }));
  };

  return (
    <div className="OCTWForm">
      <h3>{formatMessage({ id: 'octwRuleCardTooltipTitle' })}</h3>
      <McInput
        ref={refD}
        className="OCTWForm__input"
        label={formatMessage({ id: 'updateRuleDrawerFormDefaultOctw' })}
        suffix="min"
        disabled={!editMode}
        type="number"
        min={0}
        max={99999}
        value={newValues?.deOperationalConnectionTimeWindow}
        input={(e) => handleInput(e, 'deOperationalConnectionTimeWindow')}
      />
      <McInput
        ref={refE}
        className="OCTWForm__input"
        label={formatMessage({ id: 'updateRuleDrawerFormExceptionalOctw' })}
        suffix="min"
        disabled={!editMode}
        type="number"
        min={0}
        max={99999}
        value={newValues?.exOperationalConnectionTimeWindow}
        input={(e) => handleInput(e, 'exOperationalConnectionTimeWindow')}
      />
      <div className="OCTWForm__buttons-wrapper">
        {editMode ? (
          <>
            <McButton
              id="cancel"
              label={formatMessage({ id: 'cancel' })}
              appearance="neutral"
              onClick={() => setEditMode(false)}
            />
            <McButton
              id="save"
              label={formatMessage({ id: 'save' })}
              appearance="secondary"
              onClick={() => setSubmitting(true)}
            />
          </>
        ) : (
          <McButton
            id="edit"
            label={formatMessage({ id: 'edit' })}
            disabled={!octwForTerminal}
            icon="pencil"
            appearance="secondary"
            onClick={() => setEditMode(true)}
          />
        )}
      </div>
    </div>
  );
};

export default OctwForm;
