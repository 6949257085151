import { McSegmentedControl, McSegmentedControlItem } from '@maersk-global/mds-react-wrapper';
import React from 'react';

type OCCTabProps = {
  setOccDateType: Function;
  occDateType: string;
};

const PeriodSelector: React.FC<OCCTabProps> = ({ occDateType, setOccDateType }) => {
  return (
    <McSegmentedControl listchange={(e) => setOccDateType((e.target as HTMLInputElement).value)}>
      <McSegmentedControlItem selected={occDateType === 'HOURS_30_36'} value="HOURS_30_36">
        30 - 36 h
      </McSegmentedControlItem>
      <McSegmentedControlItem value="HOURS_36_42">36 - 42 h</McSegmentedControlItem>
      <McSegmentedControlItem value="HOURS_42_48">42 - 48 h</McSegmentedControlItem>
      <McSegmentedControlItem value="HOURS_48_PLUS">{'> 48 h'}</McSegmentedControlItem>
    </McSegmentedControl>
  );
};

export default PeriodSelector;
