export const mapFieldToDisplay = {
  moves: 'Moves',
  moveCountCriticalPercentage: 'Threshold L3',
  moveCountStretchPercentage: 'Threshold L2',
  yardCapacity: 'YardCapacity',
  reeferPlugs: 'Reefer Plugs',
  imoUnits: 'IMO Units',
  oogUnits: 'OOG Units',
  imoUnitsDescription: 'IMO Units Description',
  override: 'Restored to default values',
};
