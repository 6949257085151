import React, { Dispatch, SetStateAction } from 'react';
import useOctw from '../../../capacity/hooks/useOctw';
import Drawer from '../../../common/components/Drawer/Drawer';
import { AdjustableComponent, useClasses } from '../../../common/hooks/useClasses';
import { useRecoilValue } from 'recoil';
import { selectedRuleForEditAtom } from '../../../../state';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Maybe } from '../../../common/types';
import FormikInputField from '../../../common/components/FormikInputField/FormikInputField';
import { McButton } from '@maersk-global/mds-react-wrapper';
import z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { createUpdateRuleDto } from '../../dto/UpdateRuleDto';
import { useIntl } from '../../../common/hooks/useIntl';
import FormattedMessage from '../../../common/FormattedMessage';

export type EditRuleDrawerStyles = {
  field: string;
  buttons: string;
  form: string;
};

export type EditRuleDrawerProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export type EditRuleFormShape = {
  terminal: Maybe<string>;
  code: Maybe<string>;
  ruleName: Maybe<string>;
  deOctw: Maybe<number>;
  exOctw: Maybe<number>;
  hubId: Maybe<string>;
};

const FormValidationSchema = z.object({
  terminal: z.string(),
  code: z.string(),
  ruleName: z.string(),
  deOctw: z.number({ message: 'Value should be a number' }).positive(),
  exOctw: z.number({ message: 'Value should be a number' }).positive(),
});

const EditRuleDrawer: AdjustableComponent<EditRuleDrawerProps, EditRuleDrawerStyles> = ({ classes, open, setOpen }) => {
  const styles = useClasses(
    {
      field: 'EditRuleDrawer__field',
      buttons: 'EditRuleDrawer__buttons',
      form: 'EditRuleDrawer__form-wrapper',
    },
    classes,
  );
  const selectedRule = useRecoilValue(selectedRuleForEditAtom);
  const { formatMessage } = useIntl();
  const { updateOCTW } = useOctw();

  const onClose = () => {
    setOpen(false);
  };

  const getFormInitialValues = (): EditRuleFormShape => {
    return {
      terminal: selectedRule?.hubName || '',
      code: selectedRule?.apmtHubId,
      ruleName: selectedRule?.ruleName,
      deOctw: parseInt(selectedRule?.deOperationalConnectionTimeWindow || ''),
      exOctw: parseInt(selectedRule?.exOperationalConnectionTimeWindow || ''),
      hubId: selectedRule?.hubId,
    };
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const dto = createUpdateRuleDto(values, selectedRule);
    try {
      await updateOCTW(dto);
      onClose();
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Drawer
      title={formatMessage({ id: 'updateRuleDrawerTitle' })}
      open={open}
      setOpen={setOpen}
      onRequestClose={onClose}
      noFooter
    >
      <Formik
        initialValues={getFormInitialValues()}
        enableReinitialize
        validationSchema={toFormikValidationSchema(FormValidationSchema)}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={styles.form}>
            <div>
              <div className={styles.field}>
                <Field
                  label={formatMessage({ id: 'updateRuleDrawerFormTerminal' })}
                  type="text"
                  width={80}
                  name="terminal"
                  disabled
                  component={FormikInputField}
                />
                <ErrorMessage name="terminal" component="div" />
              </div>

              <div className={styles.field}>
                <Field
                  label={formatMessage({ id: 'updateRuleDrawerFormCode' })}
                  type="text"
                  name="code"
                  width={20}
                  disabled
                  component={FormikInputField}
                />
                <ErrorMessage name="terminal" component="div" />
              </div>

              <div className={styles.field}>
                <Field
                  label={formatMessage({ id: 'updateRuleDrawerFormRuleName' })}
                  type="text"
                  width={80}
                  name="ruleName"
                  disabled
                  component={FormikInputField}
                />
                <ErrorMessage name="ruleName" component="div" />
              </div>

              <div className={styles.field}>
                <Field
                  label={formatMessage({ id: 'updateRuleDrawerFormDefaultOctw' })}
                  type="number"
                  width={20}
                  name="deOctw"
                  suffix="min"
                  component={FormikInputField}
                />
                <ErrorMessage name="deOctw" component="div" />
              </div>

              <div className={styles.field}>
                <Field
                  label={formatMessage({
                    id: 'updateRuleDrawerFormExceptionalOctw',
                  })}
                  type="number"
                  width={20}
                  name="exOctw"
                  suffix="min"
                  component={FormikInputField}
                />
                <ErrorMessage name="exOctw" component="div" />
              </div>
              <Field type="hidden" name="hubId" />
            </div>
            <div className={styles.buttons}>
              <McButton type="button" appearance="neutral" click={onClose}>
                <FormattedMessage id="cancel" />
              </McButton>
              <McButton disabled={isSubmitting} type="submit" appearance="primary">
                <FormattedMessage id={isSubmitting ? 'updateRuleDrawerFormSubmitting' : 'updateRuleDrawerFormSubmit'} />
              </McButton>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditRuleDrawer;
