import React, { FunctionComponent } from 'react';
import FormattedMessage from '../../common/FormattedMessage';
import OctwAdminView from '../components/OctwAdminView';

const RulesContainer: FunctionComponent = () => {
  return (
    <div className="RulesContainer">
      <h2 className="container-title">
        <FormattedMessage id="rules" />
      </h2>
      <div className="RulesContainer__cards">
        <OctwAdminView />
      </div>
    </div>
  );
};

export default RulesContainer;
