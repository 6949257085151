import { useRecoilValue } from 'recoil';
import { terminalCodeUsersAtom, terminalCodeAtom } from '../../../state';
import { useRedirectToRoute } from './useRedirectToRoute';
import { SideNavItem } from '../components/SideNav/SideNav';
import { useIntl } from './useIntl';
import { AppRoutes } from '../../../config/routes';
import useAuth from './useAuth';

export const useSideNavItems = (): SideNavItem[] => {
  const redirectToRoute = useRedirectToRoute();
  const { formatMessage } = useIntl();
  const { userData, isAdmin, hasCTWAccess } = useAuth();
  const terminal = useRecoilValue(terminalCodeUsersAtom);
  const terminalCapacity = useRecoilValue(terminalCodeAtom);

  const items = [
    isAdmin || hasCTWAccess
      ? {
          title: formatMessage({
            id: 'sideNavOctw',
          }),
          icon: 'vessel-side',
          route: AppRoutes.OctwRules,
          onClick: () => {
            redirectToRoute(AppRoutes.OctwRules);
          },
        }
      : undefined,
    userData?.terminalCodes?.length
      ? {
          title: formatMessage({
            id: 'sideNavCapacity',
          }),
          icon: 'chart-line-up',
          route: AppRoutes.CapacityId,
          onClick: () => {
            redirectToRoute(AppRoutes.CapacityId, { id: terminalCapacity || userData.terminalCodes[0] });
          },
        }
      : undefined,
    isAdmin
      ? {
          title: formatMessage({
            id: 'sideNavUsers',
          }),
          icon: 'people',
          route: AppRoutes.Users,
          secondaryRoute: AppRoutes.GlobalUsers,
          separator: true,
          children: [
            {
              title: formatMessage({ id: 'usersPageTitle' }),
              icon: 'user',
              route: AppRoutes.Users,
              onClick: () => {
                redirectToRoute(AppRoutes.Users, { id: terminal || userData?.terminalCodes?.[0] });
              },
            },
            {
              title: formatMessage({ id: 'globalUsersPageTitle' }),
              icon: 'globe',
              route: AppRoutes.GlobalUsers,
              onClick: () => {
                redirectToRoute(AppRoutes.GlobalUsers);
              },
            },
          ],
        }
      : undefined,
  ].filter(Boolean);

  return items as SideNavItem[];
};
