import React, { ReactNode, useState, FC } from 'react';
import './Collapse.scss';
import { ChevronDown } from '../../icons';

export type DrawerProps = {
  children: ReactNode;
  title: string;
};

const Collapse: FC<DrawerProps> = ({ children, title }) => {
  const [show, setShow] = useState(false);

  const handleChange = () => {
    setShow(!show);
  };

  return (
    <div className="Collapse">
      <div className="Collapse__heading" onClick={handleChange}>
        {title}
        <ChevronDown className={`Collapse__icon ${show ? 'Collapse__icon--active' : ''}`} />
      </div>
      <div className={`Collapse__body ${show ? 'Collapse__body--active' : ''}`}>{children}</div>
    </div>
  );
};

export default Collapse;
