import React, { Dispatch, SetStateAction } from 'react';
import Drawer from '../../../common/components/Drawer/Drawer';
import { AdjustableComponent, useClasses } from '../../../common/hooks/useClasses';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import FormikInputField from '../../../common/components/FormikInputField/FormikInputField';
import { McButton } from '@maersk-global/mds-react-wrapper';
import z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import FormattedMessage from '../../../common/FormattedMessage';
import useCapacityData from '../../hooks/useCapacityData';
import { createOccDto } from '../dto/OccDto';

export type EditOccValuesDrawerStyles = {
  field: string;
  buttons: string;
  form: string;
};

export type EditOccValuesDrawerProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess?: Function;
  selectedTerminal: string;
  occValues: Map<string, number>;
};

export type EditOccFormShape = {
  terminalCode: string;
  from: string;
  to: string;
  vesselPairLimits: number;
  dateType: string;
  period: string;
};

const FormValidationSchema = z.object({
  terminalCode: z.string(),
  from: z.string(),
  to: z.string(),
  vesselPairLimits: z.number({ message: 'Value should be a number' }).min(0).max(999999),
});

const EditOccValuesDrawer: AdjustableComponent<EditOccValuesDrawerProps, EditOccValuesDrawerStyles> = ({
  classes,
  open,
  setOpen,
  onSuccess,
  selectedTerminal,
  occValues,
}) => {
  const styles = useClasses(
    {
      field: 'EditOccValuesDrawer__field',
      buttons: 'EditOccValuesDrawer__buttons',
      form: 'EditOccValuesDrawer__form-wrapper',
    },
    classes,
  );
  const { updateOCC } = useCapacityData();

  const onClose = () => {
    setOpen(false);
  };

  const getFormInitialValues = (): EditOccFormShape => {
    return {
      terminalCode: selectedTerminal,
      from: occValues && occValues['from'],
      to: occValues && occValues['to'],
      vesselPairLimits: occValues && occValues['vesselPairLimits'],
      dateType: occValues && occValues['dateType'],
      period: occValues && occValues['period'],
    };
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const dto = createOccDto(values);
    try {
      await updateOCC(dto);
      onSuccess?.();
      onClose();
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Drawer
      title={'Edit Overlapping Connections Limits'}
      open={open}
      setOpen={setOpen}
      onRequestClose={onClose}
      noFooter
    >
      <Formik
        initialValues={getFormInitialValues()}
        enableReinitialize
        validationSchema={toFormikValidationSchema(FormValidationSchema)}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form className={styles.form}>
            <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
              <div style={{ width: '100%', fontWeight: 'bold' }}>Period: {values.period?.toLowerCase()}</div>
              <Field label={'From (PC)'} type="text" name="from" disabled component={FormikInputField} />
              <ErrorMessage name="from" component="div" />
              <Field label={'To (OC)'} type="text" name="to" disabled component={FormikInputField} />
              <ErrorMessage name="to" component="div" />
              <Field label={'Vessel pair limits'} type="number" name="vesselPairLimits" component={FormikInputField} />
              <ErrorMessage name="number" component="div" />
            </div>

            <Field type="hidden" name="hubId" />
            <div className={styles.buttons}>
              <McButton type="button" appearance="neutral" click={onClose}>
                <FormattedMessage id="cancel" />
              </McButton>
              <McButton disabled={isSubmitting} type="submit" appearance="primary">
                <FormattedMessage id={isSubmitting ? 'updateRuleDrawerFormSubmitting' : 'updateRuleDrawerFormSubmit'} />
              </McButton>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditOccValuesDrawer;
