import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import Drawer from '../../../common/components/Drawer/Drawer';
import { formattedNumberToInt } from '../../../common/helpers/utils';
import { AdjustableComponent, useClasses } from '../../../common/hooks/useClasses';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import FormikInputField from '../../../common/components/FormikInputField/FormikInputField';
import { McButton } from '@maersk-global/mds-react-wrapper';
import z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import FormattedMessage from '../../../common/FormattedMessage';
import useCapacityData from '../../hooks/useCapacityData';
import ChangelogDrawer from '../changelog/changelog-drawer/ChangelogDrawer';
import { createWeeklyValueDto } from '../dto/WeeklyValueDto';
import IMOInputField from '../IMODescription/IMOInputField';

export type EditWeeklyValuesDrawerStyles = {
  title: string;
  field: string;
  buttons: string;
  form: string;
};

export type EditWeeklyValuesDrawerProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedTerminal: string;
  defaultValues: Map<string, number>;
  selectedWeeks: string[];
  type: 'soc' | 'yard';
  onSuccess?: Function;
};

const FormValidationSchemaSOC = z.object({
  terminalCode: z.string(),
  moves: z.number({ message: 'Value should be a number' }).positive(),
  moveCountStretchPercentage: z.number({ message: 'Value should be a number (0 - 100)' }).min(0).max(100),
  moveCountCriticalPercentage: z.number({ message: 'Value should be a number (0 - 100)' }).min(0).max(100),
});

const FormValidationSchemaYard = z.object({
  terminalCode: z.string(),
  oogUnits: z.number({ message: 'Value should be a number' }).positive(),
  imoUnits: z.number({ message: 'Value should be a number' }).positive(),
  yardCapacity: z.number({ message: 'Value should be a number' }).positive(),
  reeferPlugs: z.number({ message: 'Value should be a number' }).positive(),
});

const EditWeeklyValuesDrawer: AdjustableComponent<EditWeeklyValuesDrawerProps, EditWeeklyValuesDrawerStyles> = ({
  classes,
  open,
  setOpen,
  selectedTerminal,
  selectedWeeks,
  defaultValues,
  type,
  onSuccess,
}) => {
  const styles = useClasses(
    {
      title: 'EditWeeklyValuesDrawer__title',
      field: 'EditWeeklyValuesDrawer__field',
      buttons: 'EditWeeklyValuesDrawer__buttons',
      form: 'EditWeeklyValuesDrawer__form-wrapper',
    },
    classes,
  );
  const { updateCapacity } = useCapacityData();
  const formikRef = useRef(null);
  const [changelogOpen, setChangelogOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const getFormInitialValues = () => {
    const baseValues = {
      terminalCode: selectedTerminal,
      selectedWeeks: selectedWeeks,
      capacityDataType: defaultValues['capacityDataType'],
    };
    const socValues = {
      moves: formattedNumberToInt(defaultValues['moves']),
      moveCountStretchPercentage: defaultValues['moveCountStretchPercentage'],
      moveCountCriticalPercentage: defaultValues['moveCountCriticalPercentage'],
    };
    const yardValues = {
      yardCapacity: formattedNumberToInt(defaultValues['yardCapacity']),
      reeferPlugs: formattedNumberToInt(defaultValues['reeferPlugs']),
      imoUnits: formattedNumberToInt(defaultValues['imoUnits']),
      imoUnitsDescription: defaultValues['imoUnitsDescription'],
      oogUnits: formattedNumberToInt(defaultValues['oogUnits']),
    };
    return { ...baseValues, ...(type === 'soc' ? socValues : yardValues) };
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const dto = createWeeklyValueDto(values, type);
    try {
      await updateCapacity(dto);
      onSuccess?.();
      onClose();
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Drawer
      title={'Edit weekly values'}
      open={open}
      setOpen={setOpen}
      onRequestClose={onClose}
      noFooter
      titleAddon={
        selectedWeeks.length === 1 ? (
          <div className="EditWeeklyValuesDrawer__changelog">
            <McButton
              type="button"
              appearance="secondary"
              variant="outlined"
              click={() => setChangelogOpen(true)}
              icon="arrow-anti-clockwise-clock"
            >
              <FormattedMessage id="changelog" />
            </McButton>
          </div>
        ) : null
      }
    >
      <Formik
        initialValues={getFormInitialValues()}
        enableReinitialize
        //@ts-ignore
        validationSchema={toFormikValidationSchema(type === 'soc' ? FormValidationSchemaSOC : FormValidationSchemaYard)}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ isSubmitting, values }) => (
          <Form className={styles.form}>
            <div>
              <h3 className={styles.title}>{selectedWeeks.join(', ')}</h3>
              <hr />
              <br />

              {type === 'soc' ? (
                <div style={{ backgroundColor: '#f0f0f0', padding: '0.5rem', borderRadius: '0.25rem' }}>
                  <div style={{ display: 'flex', gap: '2rem', alignItems: 'flex-start' }}>
                    {/* Move Count */}
                    <div style={{ flexBasis: '30%', textAlign: 'left' }}>
                      <label>Move count</label>
                      <Field label={''} type="number" name="moves" component={FormikInputField} />
                      <ErrorMessage name="moves" component="div" />
                    </div>

                    {/* Threshold #1 with Icon and Total */}
                    <div style={{ flexBasis: '30%', textAlign: 'left' }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <span style={{ backgroundColor: '#FFD029', width: '1rem', height: '1rem' }}></span>
                        <label>Threshold L2</label>
                      </div>
                      <Field
                        label={''}
                        type="number"
                        name="moveCountStretchPercentage"
                        suffix="%"
                        component={FormikInputField}
                      />
                      <ErrorMessage name="moveCountStretchPercentage" component="div" />
                      <div style={{ fontSize: '0.9rem', marginTop: '0.5rem', color: '#8A8A90' }}>
                        {/*// @ts-ignore*/}
                        Total: {printTotal(values.moves, values.moveCountStretchPercentage)}
                      </div>
                    </div>

                    {/* Threshold #2 with Icon and Total */}
                    <div style={{ flexBasis: '30%', textAlign: 'left' }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <span style={{ backgroundColor: '#FF6000', width: '1rem', height: '1rem' }}></span>
                        <label>Threshold L3</label>
                      </div>
                      <Field
                        label={''}
                        type="number"
                        name="moveCountCriticalPercentage"
                        suffix="%"
                        component={FormikInputField}
                      />
                      <ErrorMessage name="moveCountCriticalPercentage" component="div" />
                      <div style={{ fontSize: '0.9rem', marginTop: '0.5rem', color: '#8A8A90' }}>
                        {/*// @ts-ignore*/}
                        Total: {printTotal(values.moves, values.moveCountCriticalPercentage)}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <Field label={'Yard TEU capacity'} type="number" name="yardCapacity" component={FormikInputField} />
                    <ErrorMessage name="yardCapacity" component="div" />
                    <Field label={'Reefer plugs'} type="number" name="reeferPlugs" component={FormikInputField} />
                    <ErrorMessage name="reeferPlugs" component="div" />
                  </div>

                  <br />
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <IMOInputField isDrawerOpen={open} />
                    <Field label={'OOG units'} type="number" name="oogUnits" component={FormikInputField} />
                    <ErrorMessage name="oogUnits" component="div" />
                  </div>
                </>
              )}

              <Field type="hidden" name="hubId" />
            </div>
            <div className={styles.buttons}>
              <McButton type="button" appearance="neutral" click={() => onClose()}>
                <FormattedMessage id="cancel" />
              </McButton>
              <McButton disabled={isSubmitting} type="submit" appearance="primary">
                <FormattedMessage id={isSubmitting ? 'updateRuleDrawerFormSubmitting' : 'updateRuleDrawerFormSubmit'} />
              </McButton>
            </div>
          </Form>
        )}
      </Formik>

      <ChangelogDrawer open={changelogOpen} setOpen={setChangelogOpen} id={defaultValues['id']} />
    </Drawer>
  );
};

const printTotal = (moves: number, percentage: number) => {
  return moves && percentage ? moves + Math.ceil((moves * percentage) / 100) : '-';
};

export default EditWeeklyValuesDrawer;
