import { McButton, McInputDate, McTable } from '@maersk-global/mds-react-wrapper';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Collapse from '../../../common/components/Collapse/Collapse';
import { dateStringToISO } from '../../../common/helpers/utils';
import useCapacityData from '../../hooks/useCapacityData';
import EditOccValuesDrawer from '../EditOccValuesDrawer/EditOccValuesDrawer';
import { mapCapacityType } from '../utils/capacityTypeMapper';
import { columns } from './consts';
import NextPeriodTable from './next-period-table';
import OctwForm from './octw-form';
import './occ-tab.scss';
import PeriodSelector from './period-selector';

type OCCTabProps = {
  selectedTerminal: string;
};

const OCCTab: React.FC<OCCTabProps> = (props) => {
  const { selectedTerminal } = props;
  const [occDateType, setOccDateType] = useState('HOURS_30_36');
  const [nextOccDateType, nextSetOccDateType] = useState('HOURS_30_36');
  const [selectedOccRow, setSelectedOccRow] = useState<any>(null);
  const { occ } = useCapacityData();
  const { formatMessage } = useIntl();

  const data = useMemo(
    () =>
      occ &&
      occ[occDateType] &&
      occ[occDateType].capacityEntries
        .filter((item) => item.period === 'CURRENT')
        .map((item, index) => ({
          id: index,
          from: mapCapacityType(item.from),
          to: mapCapacityType(item.to),
          vesselPairLimits: `${item.vesselPairLimits}`,
          dateType: occDateType,
          period: item.period,
        })),
    [occ, occDateType],
  );

  return (
    <>
      <div className="OCCTab">
        <h2>Overlapping Connections Limits</h2>
        <PeriodSelector setOccDateType={setOccDateType} occDateType={occDateType} />
        <div className="OCCTab__content">
          <div className="OCCTab__content__table">
            <McTable columns={columns} data={data} rowclick={(e) => setSelectedOccRow(e.detail.rowData)}>
              {data?.map((row) => (
                <div key={`${row.id}_action_current`} slot={`${row.id}_action`}>
                  <McButton fit="small" variant="plain" appearance="primary" icon="pencil">
                    {formatMessage({ id: 'edit' })}
                  </McButton>
                </div>
              ))}
            </McTable>
          </div>
          <OctwForm selectedTerminal={selectedTerminal} />
        </div>
        <div className="OCCTab__content__table OCCTab__collapse-wrapper">
          <Collapse title="Next period combinations">
            <>
              <div className="OCCTab__date-input-wrapper">
                <McInputDate
                  label="Valid from"
                  labelposition="left"
                  value={dateStringToISO(occ?.[nextOccDateType].nextPeriod)}
                />
              </div>
              <div className="OCCTab__period_selector">
                <PeriodSelector setOccDateType={nextSetOccDateType} occDateType={nextOccDateType} />
              </div>
              <div className="OCCTab__content">
                <div className="OCCTab__content__table">
                  <NextPeriodTable occ={occ} occDateType={nextOccDateType} setSelectedOccRow={setSelectedOccRow} />
                </div>
              </div>
            </>
          </Collapse>
        </div>
      </div>

      <EditOccValuesDrawer
        occValues={selectedOccRow}
        selectedTerminal={selectedTerminal}
        open={Boolean(selectedOccRow)}
        setOpen={() => setSelectedOccRow(null)}
      />
    </>
  );
};

export default OCCTab;
