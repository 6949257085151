import { httpClient } from '../http-client';
import { OCTWEntry, OCTWResponse } from './types';

export const octwService = () => {
  const client = httpClient();
  const BASE_URL = '/v1/octw';

  return {
    fetchOCTWRules: async (): Promise<OCTWResponse[] | null> => {
      const url = `${BASE_URL}/search`;
      try {
        const result = await client.post<OCTWResponse[]>(url, {});
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    fetchOCTWForTerminal: async ({ terminal }: { terminal: string }): Promise<OCTWResponse | null> => {
      const url = `${BASE_URL}/${terminal}`;
      try {
        const result = await client.get<OCTWResponse>(url);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    updateOCTWRule: async ({ data }: { data: OCTWEntry }): Promise<OCTWResponse | null> => {
      const url = `${BASE_URL}/update`;
      try {
        const result = await client.post<OCTWResponse>(url, data);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
  };
};

const logger = (message: string): void => console.error(`[OCTW Service] ${message}`);
